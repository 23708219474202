import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { colors } from "../utils/siteVars"
import Button from "./button"
import { Link } from "gatsby"

const StyledProduct = styled.div`
  display: flex;

  .image-container {
    min-width: 66%;
  }
  border: 1px solid ${colors.mediumGrey};
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 3rem;
  .text-container {
    padding: 1rem;
    background: ${colors.lightGreen};
    min-height: 100%;
    width: 100%;
    p {
      margin: 0;
      &.tagline {
        margin-bottom: 1rem;
        font-style: italic;
      }
    }
    h3 {
      margin: 0 0 0.3rem 0;
    }

    ul {
      margin-left: 1rem;
      list-style: initial;
      li {
        margin-bottom: 0.1rem;
      }
    }
  }
  a {
    button {
      padding: 1rem;
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Product = props => {
  const { product } = props
  return (
    <StyledProduct>
      <div className="image-container">
        <Img
          fluid={product.image}
          alt={product.name}
          imgStyle={{ objectFit: "cover", minHeight: "100%" }}
        />
      </div>
      <div className="text-container">
        <div className="flex-grow">
          <h3>{product.name}</h3>
          <p className="tagline">{product.tagline}</p>
          <p>{product.description}</p>
        </div>
        {product.link ? (
          <Link to={`/produkter/${product.link}`}>
            <Button>Läs mer</Button>
          </Link>
        ) : null}
        {/* {product.pdfLink ? (
          <a href={product.pdfLink} download={product.pdfLink.replace("/", "")}>
            <Button>Ladda ned PDF</Button>
          </a>
        ) : null} */}
      </div>
    </StyledProduct>
  )
}

export default Product
