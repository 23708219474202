import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledContentbox = styled.div`
  background: ${props => props.bg || colors.lightGreen};
  border-radius: 0.5rem;
  padding: 1rem;
`

const Contentbox = props => {
  return (
    <StyledContentbox className={props.className}>
      {props.children}
    </StyledContentbox>
  )
}

export default Contentbox
