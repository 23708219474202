import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Button from "../../components/button"
import Contentbox from "../../components/contentbox"
import H1 from "../../components/h1"
import IconButton from "../../components/iconButton"
import Input from "../../components/input"
import Layout from "../../components/layout"
import MapLink from "../../components/mapLink"
import Narrow from "../../components/narrow"
import Product from "../../components/product"
// import QuoteBox from "../../components/quoteBox"
import Textarea from "../../components/textarea"

const StyledTeknikenPage = styled.div`
  .narrow {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
  .products-download-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
    p {
      margin: 0;
      margin-bottom: 1.5rem;
    }
    h1 {
      margin-bottom: 0.5rem;
    }
  }
`

const TeknikenPage = () => {
  const query = useStaticQuery(graphql`
    query teknikQuery {
      image: file(relativePath: { eq: "tech-2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      secondary: file(relativePath: { eq: "tech-1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      gosta: file(relativePath: { eq: "wash-top.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bernt: file(relativePath: { eq: "wash-container.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      jenny: file(relativePath: { eq: "IMG_0340.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      nicholas: file(relativePath: { eq: "nicholas_3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bikeMount: file(relativePath: { eq: "bike-mount.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      washingFluid: file(relativePath: { eq: "washing-fluid.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pumpHead: file(relativePath: { eq: "pumphead_1.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      lisa: file(relativePath: { eq: "lisa.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      johan: file(relativePath: { eq: "johan.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const products = [
    {
      image: query.gosta.childImageSharp.fluid,
      name: "Gösta",
      tagline: "Med nöjda cyklister och miljön i åtanke.",
      link: "gösta",
      description:
        "Denna modell av cykeltvätt kan får med kompressor för snabbtorkning och luftpåfyllning. Fungerar med korgar, väskor och de flesta barnstolar samt elcyklar och mindre mopeder. Klarar tvättning i temperaturer ner till minus 3 grader.",
      pdfLink: "/cykeltvätten.se_Gösta_produktblad.pdf",
    },
    {
      image: query.bernt.childImageSharp.fluid,
      name: "Bernt",
      tagline: "För bästa komfort och säkerhet",
      link: "bernt",
      description:
        "Denna modell av utomhus-cykeltvätt kan användas året runt. Den är försedd med låsbara dörrar och cykeltvätten är placerad invändigt. Klarar tvättning i tempraturer ner till minus 42 grader.",
      pdfLink: "/cykeltvätten.se_Bernt_produktblad.pdf",
    },
    {
      image: query.jenny.childImageSharp.fluid,
      name: "Jenny",
      tagline: "Spolplatta",
      description: "Komplett station för manuell cykeltvätt",
      link: "jenny",
    },
    {
      image: query.nicholas.childImageSharp.fluid,
      name: "Nicholas",
      tagline: "Cykeltvätt",
      description:
        "Väggförankrad, ergonomisk cykeltvätt. För inomhus- och utomhusbruk.",
      link: "nicholas",
    },

    {
      image: query.lisa.childImageSharp.fluid,
      name: "Lisa",
      tagline: "Större helautomatisk variant med tork.",
    },
    {
      image: query.johan.childImageSharp.fluid,
      name: "Johan",
      tagline: "Torkmodul",
      description: "Kan monteras i linje med Gösta",
    },
    {
      image: query.bikeMount.childImageSharp.fluid,
      name: "Diagonalhängt cykelställ",
      tagline: "Enkelt, snyggt och hållbart",
      link: "cykelställ",
      description:
        "Vinkelmonteringen gör att cyklar hänger extremt nära varandra, utan att fastna eller hindra varandra vid upphängning eller användning.",
    },
    {
      image: query.washingFluid.childImageSharp.fluid,
      name: "Tvättmedel",
      tagline: "Vårat ekologiska tvättmedel",
    },
    {
      image: query.pumpHead.childImageSharp.fluid,
      name: "Pumphuvud",
      tagline:
        "Passar alla ventiler: cykel, bil och racer. Monteras på befintlig slang.",
    },
  ]

  return (
    <Layout
      headerImage={query.image.childImageSharp.fluid}
      headerText="Produkter"
      helmet="Produkter"
    >
      <StyledTeknikenPage>
        <Narrow>
          <div className="products-download-container">
            <H1>Produkter</H1>
            <p className="centered">
              Ladda gärna ner broschyren och läs om alla våra tvättar.
            </p>
            <a href={"/cykeltvattar.pdf"} download={"cykeltvattar.pdf"}>
              <IconButton src="/images/doc.svg">Ladda ned PDF</IconButton>
              {/* <Button>Ladda ned PDF</Button> */}
            </a>
          </div>
          {products.map(product => (
            <Product key={product.name} product={product} />
          ))}
          <H1>Offertförfrågan</H1>
          <p className="centered">
            Vi har även en del demoprodukter och begagnade maskiner. Hör av dig
            för att få veta mer.
          </p>
          <Contentbox>
            <form
              // onSubmit={handleSubmit}
              method="post"
              action="/tack"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="Offert"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="Offert" />
              <Input
                name="Namn"
                type="text"
                // value={name}
                // onChange={e => {
                //   setName(e.target.value)
                // }}
                label="Ditt namn"
                placeholder="Ditt namn"
              />
              <Input
                name="E-post"
                type="email"
                // value={email}
                // onChange={e => {
                //   setEmail(e.target.value)
                // }}
                label="E-post"
                placeholder="E-post"
              />
              <Textarea
                name="Meddleande"
                label="Ditt meddelande"
                // value={message}
                // onChange={e => {
                //   setMessage(e.target.value)
                // }}
                placeholder="Ditt meddelande"
              />
              <Button type="submit">Skicka</Button>
            </form>
          </Contentbox>
          <p className="centered">
            Vi erbjuder montering, leverans i egen regi, serviceavtal, leasing,
            hyra mm.
          </p>
          <div className="narrow">
            <Link to="/hitta">
              <MapLink />
            </Link>
          </div>
        </Narrow>
      </StyledTeknikenPage>
    </Layout>
  )
}

export default TeknikenPage
