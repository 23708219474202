import React from "react"
import styled from "styled-components"
import Button from "./button"

const StyledIconButton = styled.div`
  button {
    display: flex;
    align-items: center;
  }
  img {
    width: 20px;
    margin-right: 0.5rem;
    transition: 200ms ease-in-out;
  }
  &:hover {
    img {
      filter: invert(1);
    }
  }
`

const IconButton = ({ src, children }) => {
  return (
    <StyledIconButton>
      <Button>
        <img src={src} alt="" />
        {children}
      </Button>
    </StyledIconButton>
  )
}

export default IconButton
